<script setup lang="ts">
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'

const { isOwner } = storeToRefs(useUserStore())
const { t } = useI18n()
const { all: bookingsGuest } = storeToRefs(useBookingsGuestStore())
const { all: bookingsOwner } = storeToRefs(useBookingsOwnerStore())
const { hasOnlyOneCamper } = storeToRefs(useCamperStore())
const { getTranslatedLink } = useGetTranslatedLink()

const links = computed(() => {
  const { TO_DO } = Constants.BOOKINGS.LISTING_STATE
  const bookingsGuestTab = {
    to: { name: 'd-trips' },
    label: getTranslatedLink('d-trips'),
    notification: bookingsGuest.value[TO_DO].count > 0,
    order: 10,
  }
  const first = [
    {
      to: { name: 'd-chats' },
      label: getTranslatedLink('d-chats'),
      notification: false,
      order: 20,
    },
    {
      to: { name: 'd-reviews' },
      label: getTranslatedLink('d-reviews'),
      notification: false,
      order: 40,
    },
    {
      to: { name: 'd-account' },
      label: getTranslatedLink('d-account'),
      notification: false,
      order: 60,
    },
  ]
  const second = []

  if (isOwner.value) {
    first.push(
      {
        to: { name: 'd-bookings' },
        label: getTranslatedLink('d-bookings'),
        notification: bookingsOwner.value[TO_DO].count > 0,
        order: 10,
      },
      {
        to: { name: 'd-campers' },
        label: t('commons.links.products_plural', hasOnlyOneCamper.value ? 1 : 2),
        notification: false,
        order: 30,
      },
    )
    second.push(
      ...[
        bookingsGuestTab,
        {
          to: { name: 'd-wishlists' },
          label: getTranslatedLink('d-wishlists'),
          notification: false,
        },
      ],
    )
  }
  else {
    first.push(
      ...[
        bookingsGuestTab,
        {
          to: { name: 'd-wishlists' },
          label: getTranslatedLink('d-wishlists'),
          notification: false,
          order: 30,
        },
      ],
    )
    second.push(
      ...[
        {
          to: { name: 'onboarding-step' },
          label: getTranslatedLink('onboarding-step'),
          notification: false,
        },
      ],
    )
  }

  return [first.sort((a, b) => a.order - b.order), second]
})
</script>

<template>
  <div class="bg-peacock-700 relative">
    <div class="container-dashboard">
      <div
        class="after:from-peacock-700 relative after:absolute after:inset-0 after:left-auto after:w-4 after:bg-gradient-to-l md:after:hidden"
      >
        <AppContentScroller
          class="justify-between space-x-4 lg:space-x-8"
          scroll-indicator-color="from-peacock-700 via-peacock-700"
        >
          <nav
            v-for="(group, i) in links"
            :key="`group-link-${i}`"
            class="flex items-center"
          >
            <div
              v-for="(link, j) of group"
              :key="`group-link-${i}-${j}`"
              class="relative"
            >
              <NuxtLink
                :to="link.to"
                class="hover:bg-peacock-900 block whitespace-nowrap px-6 py-4 text-white"
                active-class="font-semibold bg-peacock-900"
              >
                {{ link.label }}
              </NuxtLink>

              <YscNotification
                v-if="link.notification"
                class="absolute right-2 top-4"
              />
            </div>
          </nav>
        </AppContentScroller>
      </div>
    </div>
  </div>
</template>
